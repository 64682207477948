import { cva } from "class-variance-authority";
import { menu } from "../menu/menu.styles";

const root = menu.root;

const separator = menu.separator;

const label = cva(`flex items-center px-2 h-8`);

const item = menu.item;

const trigger = cva(`focus:outline-none`);

export const dropdown = { root, trigger, separator, item, label };
